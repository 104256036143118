import '../../resources/styles/reports.css';
import '../../resources/styles/styles.css';
import ReportTable from '../../components/ReportTable';
import ReportLine from '../../components/ReportLine';

const ReportMainData = ({loading, data, isAdmin}) => {
    return <>{!loading && <>
            <div className={"order-title reports-title"}>Общие значения</div>
            <ReportTable columns={["Параметр", "Значение"]}>
                <ReportLine keyPrefix={"mrs"} 
                    data={["Сумма оплаченных счетов", {value: data.sums.sumPaid.toFixed(2), type: "money"}]}>
                        {isAdmin && <>
                        <ReportLine data={["Сумма оплаченных счетов по картам *",
                                 {value: data.sums.sumPaidProductsByCard.toFixed(2), type: "money"}]} isChild/>
                        <ReportLine data={["Сумма оплаченных счетов через СБП *",
                                 {value: data.sums.sumPaidProductsBySBP.toFixed(2), type: "money"}]} isChild/>
                        </>}
                </ReportLine>

                <ReportLine keyPrefix={"mrts"} 
                    data={["Сумма чаевых со счетами", {value: data.sums.sumTipsWithOrders.toFixed(2), type: "money"}]}>
                        {isAdmin && <>
                        <ReportLine data={["Сумма чаевых со счетами по картам *",
                                 {value: data.sums.sumTipsWithOrdersByCard.toFixed(2), type: "money"}]} isChild/>
                        <ReportLine data={["Сумма чаевых со счетами по СБП *",
                                 {value: data.sums.sumTipsWithOrdersBySBP.toFixed(2), type: "money"}]} isChild/>
                        </>}
                </ReportLine>

                <ReportLine keyPrefix={"mrtps"} 
                    data={["Сумма чаевых без счетов", {value: data.sums.sumTipsWithoutOrders.toFixed(2), type: "money"}]}>
                        {isAdmin && <>
                        <ReportLine data={["Сумма чаевых без счетов по картам *",
                                 {value: data.sums.sumTipsWithoutOrdersByCard.toFixed(2), type: "money"}]} isChild/>
                        <ReportLine data={["Сумма чаевых без счетов по СБП *",
                                 {value: data.sums.sumTipsWithoutOrdersBySBP.toFixed(2), type: "money"}]} isChild/>
                        </>}
                </ReportLine>

                {isAdmin && <ReportLine keyPrefix={"mrpas"} 
                    data={["Средний чек + чай (со счетом) *", {value: data.sums.avgBillWithTips.toFixed(2), type: "money"}]}>
                        <ReportLine data={["Средний счет в ресторане *",
                                 {value: data.sums.avgOrder.toFixed(2), type: "money"}]} isChild/>
                        <ReportLine data={["Средний счет через e&s *",
                                 {value: data.sums.avgEnSOrder.toFixed(2), type: "money"}]} isChild/>
                        <ReportLine data={["Средняя транзакция *",
                                 {value: data.sums.avgTransaction.toFixed(2), type: "money"}]} isChild/>
                </ReportLine>}
                {!isAdmin && <>
                    <ReportLine data={["Средний чек",
                                 {value: data.sums.avgEnSOrder.toFixed(2), type: "money"}]}/>
                    <ReportLine data={["Средний чек на гостя",
                                 {value: data.sums.avgEnSPerTrans.toFixed(2), type: "money"}]}/>
                    <ReportLine data={["Процент чаевых от суммы счетов",
                                 {value: data.sums.prcTipsFromTrans.toFixed(2), type: "percent"}]}/>

                </>}
                {isAdmin && <>
                    <ReportLine data={["Процент чаевых со счетом *",
                                    {value: data.sums.prcTipsFromTrans.toFixed(2), type: "percent"}]}/>
                    <ReportLine data={["Процент чаевых без счета *",
                                    {value: data.sums.prcTipsOnly.toFixed(2), type: "percent"}]}/>
                    <ReportLine data={["Оборот ресторана *",
                                    {value: data.sums.sumOrders.toFixed(2), type: "money"}]}/>
                    <ReportLine data={["Транзакции кол *", data.sums.countTransactions.toFixed(2)]}>
                        <ReportLine data={["Транзакции счет *", data.sums.countTransactionsWithProducts.toFixed(2)]} isChild/>
                        <ReportLine data={["Транзакции чай вместе со счетом *", data.sums.countTipsWithProductsTransactions.toFixed(2)]} isChild/>
                        <ReportLine data={["Транзакции чай отдельно *", data.sums.countTransactionsTipsWithoutProducts.toFixed(2)]} isChild/>
                    </ReportLine>
                    <ReportLine data={["Процент оборота e&s от оборота ресторана *",
                                    {value: data.sums.prcEnSInOrders.toFixed(2), type: "percent"}]}/>
                    <ReportLine data={["Сумма сервисного сбора *",
                                    {value: data.sums.sumService.toFixed(2), type: "money"}]}>
                        <ReportLine data={["Сервисный сбор с разницей эквайринга *",
                                 {value: data.sums.sumServiceDiff.toFixed(2), type: "money"}]} isChild/>
                        <ReportLine data={["Процент отжатия кнопки взять расходы сервиса *",
                                 {value: data.sums.prcNotPaidService.toFixed(2), type: "percent"}]} isChild/>
                        <ReportLine data={["Сумма отжатия кнопки *",
                                 {value: data.sums.notPaidService.toFixed(2), type: "money"}]} isChild/>
                        <ReportLine data={["Процент суммы отжатия кнопки *",
                                 {value: data.sums.prcNotPaidServiceBySum.toFixed(2), type: "percent"}]} isChild/>
                    </ReportLine>
                    <ReportLine data={["Сумма кэшбека",
                                    {value: data.sums.sumCashbacks.toFixed(2), type: "money"}]}/>
                </>}

            </ReportTable>
    </>    
    }
    </>;
};

export default ReportMainData;
